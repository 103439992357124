import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { resetPassword } from "../../lib/api"

//Styled components imports
import Wrapper from "./Wrapper"
import InputWrapper from "./InputWrapper"
import Text from "./Text"
import InputField from "../../shared-styled-components/InputField"
import Button from "../../shared-styled-components/Button"
import ErrorMessage from "../../shared-styled-components/ErrorMessage"
import SuccessfulMessage from "../../shared-styled-components/SuccessfulMessage"
import CheckIcon from "../../shared-styled-components/CheckIcon"

const SignUpForm = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState({})
  const [successful, setSuccessful] = useState(false)
  const intl = useIntl()

  const handleSubmit = async e => {
    e.preventDefault()

    if (email === "") {
      setError({ email: "Vyplnte prosím e-mail." })
    } else {
      const res = await resetPassword({
        email,
      })

      if (!res.valid) {
        setError({
          email:
            res.data ===
            "Neexistuje žádný účet registrovaný k této e-mailové adres"
              ? intl.formatMessage({
                  id: "forgotten_password_form.no_account",
                })
              : intl.formatMessage({
                  id: "forgotten_password_form.no_account",
                }),
        })
      } else {
        setError({})
        setSuccessful(true)
      }
    }
  }

  const handleChange = e => {
    setEmail(e.target.value)
  }

  return (
    <Wrapper>
      {!successful ? (
        <>
          <Text>
            {intl.formatMessage({
              id: "forgotten_password_form.info",
            })}
          </Text>
          <InputWrapper style={{ margin: "25px 0" }}>
            <label htmlFor="email">
              {intl.formatMessage({
                id: "forgotten_password_form.your_email",
              })}
            </label>
            <div style={{ position: "relative" }}>
              <InputField
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
              />
              {error.email && <ErrorMessage>{error.email}</ErrorMessage>}
            </div>
          </InputWrapper>
          <Button black onClick={handleSubmit}>
            {intl.formatMessage({
              id: "forgotten_password_form.send",
            })}
          </Button>
        </>
      ) : (
        <SuccessfulMessage>
          <CheckIcon size="36" />
          {intl.formatMessage({
            id: "forgotten_password_form.confirmation_email",
          })}{" "}
          {email}
        </SuccessfulMessage>
      )}
    </Wrapper>
  )
}

export default SignUpForm
